import { World } from '@metavrse-inc/metavrse-lib';

const worldObject = (): World => ({
  skybox: {
    key: '',
    show: true,
  },
  color: [0, 0, 0],
  transparent: false,
  skyboxRotation: [0, 0, 0],
  shadow: {
    level: 2,
    enabled: false,
    position: [1, 1, 2],
    fov: false,
    texture: [2048, 2048],
  },
  controller: '',
  fps: 30,
  dpr: 0.25,
  fxaa: 1,
  orientation: 1,
  hudscale: 1,
  css: '',

  physics_debug_level: 0,
  fov_size: [500, 500, 500],
  render_method: 0,
  fov_enabled: false,
  lod_enabled: false,

  zip_enabled: false,
  zip_size: [1000, 1000, 1000],

});

export default {
  worldObject,
};
