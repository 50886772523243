import {
  Asset,
  AssetType,
  CherryKey,
  Entities,
  NODE_TYPES,
  ObjectDataSet,
  objectsDataSet,
  TreeNode,
  TreeNodeType,
} from '@metavrse-inc/metavrse-lib';
import { current, Draft, produce } from 'immer';

import copyString from 'utils/copyString';

import { PrimitiveType } from 'models/asset';
import { EntitiesTypes } from 'models/atoms';
import { ITargetPath } from 'models/entity/common';

import assetsHelpers, { AssetIntrinsics } from './assets-helpers';

export type GenericNode<T> = {
  children: T[];
  key: CherryKey;
  type: EntitiesTypes;
  title: string;
  visible?: boolean;
  id?: CherryKey;
  intrinsics?: AssetIntrinsics;
};

export type GenericNodeRemove<T> = GenericNode<T> & {
  removed?: boolean;
};

export type KeyPairs = Record<string, CherryKey>; // key: NEW_KEY, value: OLD_KEY

const getNodeBy = <T extends GenericNode<T>>(
  targetPath: ITargetPath,
  tree: T[]
): T | undefined => {
  let result;
  produce(tree, (draft) => {
    const deep = (nodes: Draft<T>[], s: ITargetPath) => {
      for (let i = 0, n = s.length; i < n; ++i) {
        const index: number = s[i];

        if (nodes[index]) {
          if (nodes[index].children && s.length !== i + 1) {
            nodes = nodes[index].children;
          } else {
            result = { ...nodes[index] };
          }
        } else {
          return;
        }
      }
      return nodes;
    };

    deep(current(draft), targetPath);
  });
  return result;
};

const getAllNodesBy = <T extends GenericNode<T>>(
  type: TreeNodeType | AssetType,
  tree: T[]
): T[] => {
  const data: T[] = [];
  produce(tree, (draft) => {
    const getNodes = (nodes: Draft<T>[]) => {
      for (let i = 0; i < nodes.length; i++) {
        const n = nodes[i];

        if (n.type === type) {
          data.push({ ...n } as T);
        }

        getNodes(n.children);
      }
    };

    getNodes(current(draft));
  });
  return data;
};

const isAnyChildHighlighted = (node: TreeNode) => {
  const deepSearch = (nodes) => {
    let found = false;
    for (const node of nodes) {
      if (node.uiHighlighted) {
        return true;
      }
      if (node.children?.length) {
        const show = deepSearch(node.children);
        if (show) return true;
        found = show;
      }
    }
    return found;
  };
  if (node.children?.length > 0) {
    const result = deepSearch(node.children);
    return result;
  }
  return false;
};

const getPathBy = <T extends GenericNode<T>>(
  key: CherryKey,
  tree: T[]
): number[] => {
  const path: number[] = [];

  produce(tree, (draft) => {
    const deepSearch = (nodes: Draft<T>[]): boolean => {
      let isNodeFound = false;

      nodes.forEach((node, index) => {
        if (isNodeFound) return;
        const { key: nodeKey, children } = node;
        if (nodeKey === key) {
          if (children?.length) {
            deepSearch(children);
          }

          isNodeFound = true;
          path.unshift(index);
        } else {
          if (children?.length) {
            const found = deepSearch(children);
            isNodeFound = found;

            if (found) {
              path.unshift(index);
            }
          }
        }
      });

      return isNodeFound;
    };

    deepSearch(current(draft));
  });

  return path;
};

const getNodeByKey = <T extends GenericNode<T>>(
  key: CherryKey,
  tree: T[]
): T | null => {
  let treeNode: T | null = null;

  produce(tree, (draft) => {
    const getNodes = (nodes: Draft<T>[]) => {
      for (let i = 0; i < nodes.length; i++) {
        const n = nodes[i];

        if (n.key === key) {
          treeNode = n as T;
          return;
        }

        getNodes(n.children);
      }
    };

    getNodes(current(draft));
  });

  return treeNode;
};

const getNodeByProperty = <T extends GenericNode<T>>(
  property: 'key' | 'id' | 'title' | 'type',
  value: string | number,
  tree: T[]
): T[] => {
  const data: T[] = [];

  produce(tree, (draft) => {
    const getNodes = (nodes: Draft<T>[]) => {
      for (let i = 0; i < nodes.length; i++) {
        const n = nodes[i];

        if (n[property] === value) {
          data.push({ ...n } as T);
        }

        getNodes(n.children);
      }
    };

    getNodes(current(draft));
  });

  return data;
};

const getParentNode = <T extends GenericNode<T>>(
  key: CherryKey,
  tree: T[]
): T | undefined => {
  const path = getPathBy(key, tree);
  const newPath = path.slice(0, -1);

  return getNodeBy(newPath, tree);
};

const checkIfCanAddTo = <T extends GenericNode<T>>(
  targetPath: ITargetPath,
  tree: T[],
  type: TreeNodeType | AssetType
): boolean => {
  const node = getNodeBy(targetPath, tree);

  if (targetPath.length === 0) {
    return true;
  }

  if (node && node?.title !== '.primitives') {
    const canAddToObjectGroup: TreeNodeType[] = [
      NODE_TYPES.light,
      NODE_TYPES.camera,
      NODE_TYPES.video,
      NODE_TYPES.object,
      NODE_TYPES.objectGroup,
      NODE_TYPES.hud,
      NODE_TYPES.RigidBody,
      NODE_TYPES.RaycastVehicle,
      NODE_TYPES.KinematicCharacterController,
    ];

    const canAddToFolder: AssetType[] = [
      NODE_TYPES.folder,
      NODE_TYPES.image,
      NODE_TYPES.javascript,
      NODE_TYPES.object,
      NODE_TYPES.video,
    ];

    const canAddToHud: TreeNodeType[] = [
      NODE_TYPES.object,
      NODE_TYPES.objectHud,
    ];

    const canAddToObject: TreeNodeType[] = [
      NODE_TYPES.RigidBody,
      NODE_TYPES.RaycastVehicle,
      NODE_TYPES.KinematicCharacterController,
    ];

    switch (node.type) {
      case NODE_TYPES.objectGroup:
        return canAddToObjectGroup.includes(type as TreeNodeType);
      case NODE_TYPES.hud:
        return canAddToHud.includes(type as TreeNodeType);
      case NODE_TYPES.folder:
        return canAddToFolder.includes(type as AssetType);
      case NODE_TYPES.object:
        return canAddToObject.includes(type as TreeNodeType);
      default:
        break;
    }
  }
  return false;
};

const addTo = <T extends GenericNode<T>>(
  tree: T[],
  newNode: T,
  targetPath: ITargetPath
): T[] =>
  produce(tree, (draft) => {
    if (targetPath.length === 0) {
      draft.push(newNode as Draft<T>);
    } else {
      let treeFragment = [...draft];

      for (let i = 0; i < targetPath.length; i++) {
        const index = targetPath[i];
        const element = treeFragment[index];

        if (targetPath.length === i + 1) {
          element.children.push(newNode as Draft<T>);
        }

        if (element.children && targetPath.length - 1 !== i) {
          treeFragment = [...element.children];
        }
      }
    }
    return draft;
  });

const addBefore = <T extends GenericNode<T>>(
  destinationPath: ITargetPath,
  newNode: T,
  treeData: T[]
): T[] =>
  produce(treeData, (draft) => {
    const iterateDestinationPath = (
      treeData: Draft<T>[],
      level: number,
      path: ITargetPath
    ) => {
      const index = path[level];
      if (path.length === level + 1) {
        treeData.splice(index, 0, newNode as Draft<T>);
      } else if (treeData[index].children.length && path.length !== level + 1) {
        iterateDestinationPath(treeData[index].children, level + 1, path);
      }
    };

    iterateDestinationPath(draft, 0, destinationPath);
  });

const copy = <T extends GenericNode<T>>(
  tree: T | T[] | null,
  getIncrementalId: (dummy: string) => string
): [T[], KeyPairs] => {
  if (!tree) {
    return [[], {}];
  }
  const keyPairs: KeyPairs = {};
  const treeArray = Array.isArray(tree) ? tree : [tree];
  const result = produce(treeArray, (draft) => {
    let cx = 0;
    const createCopies = (nodes: Draft<T>[]) => {
      const data = [];
      for (let i = 0; i < nodes.length; i++) {
        const n = nodes[i];
        const newKey = getIncrementalId(n.key);

        n.title = copyString(n.title, cx++ == 0);
        keyPairs[newKey] = n.key;
        n.key = newKey;

        if (n.children) {
          n.children = createCopies(n.children);
        }
        data.push(n);
      }
      return data;
    };

    createCopies(draft);
  });

  return [result, keyPairs];
};

const paste = <T extends GenericNode<T>>(
  data: T[],
  tree: T[],
  targetPath: ITargetPath
): T[] =>
  produce(tree, (draft) => {
    if (!targetPath.length) {
      draft.push(data[0] as Draft<T>);
    } else {
      const iterateTargetPath = (treeData: Draft<T>[], level: number) => {
        const index = targetPath[level];
        if (!treeData[index]) {
          return;
        }

        if (targetPath.length === level + 1) {
          treeData[index].children.push(data[0] as Draft<T>);
        } else if (
          treeData[index].children.length &&
          targetPath.length !== level + 1
        ) {
          iterateTargetPath(treeData[index].children, level + 1);
        }
      };

      iterateTargetPath(draft, 0);
    }
  });

const updateNodeByKey = <T extends GenericNode<T>>(
  key: CherryKey,
  node: Draft<T>,
  tree: T[]
): T[] =>
  produce(tree, (draft) => {
    const replaceNode = (treeData: Draft<T>[]) => {
      return treeData.map((treeNode) => {
        if (treeNode.key === key) {
          treeNode = { ...treeNode, title: node.title };
          if ('visible' in node && 'visible' in treeNode) {
            treeNode = {
              ...treeNode,
              visible: node.visible,
            };
          }
        }
        return treeNode;
      });
    };
    return replaceNode(draft);
  });

const addChildrenToNode = <T extends GenericNode<T>>(
  targetPath: ITargetPath,
  children: T[],
  tree: T[],
  replace?: boolean
): T[] =>
  produce(tree, (draft) => {
    const iterateTargetPath = (treeData: Draft<T>[], level: number) => {
      const index = targetPath[level];
      if (!treeData[index]) {
        return;
      }

      if (targetPath.length === level + 1) {
        if (replace) {
          treeData[index] = { ...treeData[index], children: children };
        } else {
          treeData[index] = {
            ...treeData[index],
            children: [...treeData[index].children, ...children],
          };
        }
      }

      if (
        treeData[index]?.children?.length &&
        targetPath.length !== level + 1
      ) {
        iterateTargetPath(treeData[index].children, level + 1);
      }
    };

    iterateTargetPath(draft, 0);
  });

const updateNodeBy = <T extends GenericNode<T>>(
  targetPath: ITargetPath,
  node: Draft<T>,
  tree: T[]
): T[] =>
  produce(tree, (draft) => {
    const iterateTargetPath = (treeData: Draft<T>[], level: number) => {
      const index = targetPath[level];
      if (!treeData[index]) {
        return;
      }

      if (targetPath.length === level + 1) {
        treeData[index] = { ...treeData[index], title: node.title };

        if ('visible' in node && 'visible' in treeData[index]) {
          treeData[index] = {
            ...treeData[index],
            visible: node.visible,
          };
        }

        if ('intrinsics' in node) {
          treeData[index] = {
            ...treeData[index],
            intrinsics: node.intrinsics,
          };
        }
      }

      if (
        treeData[index]?.children?.length &&
        targetPath.length !== level + 1
      ) {
        iterateTargetPath(treeData[index].children, level + 1);
      }
    };

    iterateTargetPath(draft, 0);
  });

const updateNodesVisibility = <T extends GenericNode<T>, E extends Entities>(
  tree: T[],
  entities: E
): T[] =>
  produce(tree, (draft) => {
    const iterateTargetPath = (treeData: Draft<T>[]) => {
      return treeData.map((node) => {
        if (entities[node.key] && !node.type.includes('-link')) {
          node.visible = !!entities[node.key].visible;
        }

        if (!!node.children.length) {
          node.children = iterateTargetPath(node.children);
        }

        return node;
      });
    };

    iterateTargetPath(draft);
  });

const removeNodeByKey = <T extends GenericNode<T>>(
  key: CherryKey,
  tree: T[]
): T[] =>
  produce(tree, (draft) => {
    const findAndRemoveNode = (nodes: Draft<T>[]) => {
      for (let i = 0; i < nodes.length; i++) {
        const n = nodes[i];

        if (n.key === key) {
          nodes.splice(i, 1);
          return;
        }

        findAndRemoveNode(n.children);
      }
    };
    findAndRemoveNode(draft);
  });

const removeNode = <T extends GenericNode<T>>(
  targetPath: ITargetPath,
  tree: T[]
): T[] =>
  produce(tree, (draft) => {
    if (targetPath.length === 1) {
      draft.splice(targetPath[0], 1);
    } else {
      const iterateTargetPath = (treeData: Draft<T>[], level: number) => {
        const index = targetPath[level];
        if (!treeData[index]) {
          return;
        }

        if (targetPath.length === level + 1) {
          treeData.splice(index, 1);
        } else if (
          treeData[index].children.length &&
          targetPath.length !== level + 1
        ) {
          iterateTargetPath(treeData[index].children, level + 1);
        }
      };

      iterateTargetPath(draft, 0);
    }
  });

const iterateTree = <T extends GenericNode<T>>(
  tree: T[],
  callback: (node: T, parentNode?: T) => void,
  parentNode?: T
): void => {
  const iterate = (nodes: T[], parentNode?: T) => {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      callback(node, parentNode);

      if (node.children) {
        iterate(node.children, node);
      }
    }
  };

  iterate(tree, parentNode);
};

const canMove = (target: number[], source: number[]): boolean => {
  if (target.toString() === source.toString()) {
    return false;
  }

  if (target.length > source.length) {
    return true;
  }

  return target.some((t, i) => t !== source[i]);
};

const changeOrder = <T extends GenericNodeRemove<T>>(
  tree: T[],
  sourcePath: ITargetPath,
  destinationPath: ITargetPath
): T[] => {
  return produce(tree, (draft) => {
    let targetElement: Draft<T> | null = null;

    const iterateSourcePath = (
      treeData: Draft<T>[],
      level: number,
      path: ITargetPath
    ) => {
      const index = path[level];

      if (path.length === level + 1) {
        targetElement = { ...treeData[index] };
        treeData[index]['removed'] = true;
      } else if (treeData[index].children.length && path.length !== level + 1) {
        iterateSourcePath(treeData[index].children, level + 1, path);
      }
    };

    iterateSourcePath(draft, 0, sourcePath);

    const iterateDestinationPath = (
      treeData: Draft<T>[],
      level: number,
      path: ITargetPath
    ) => {
      const index = path[level];

      if (path.length === level + 1) {
        if (targetElement) {
          treeData.splice(index, 0, targetElement);
        }
      } else if (treeData[index].children.length && path.length !== level + 1) {
        iterateDestinationPath(treeData[index].children, level + 1, path);
      }
    };

    iterateDestinationPath(draft, 0, destinationPath);

    if (targetElement) {
      const iterateToRemove = (treeData: Draft<T>[]) => {
        treeData.forEach((d, index) => {
          if (d.title !== '.primitives') {
            if (d.hasOwnProperty('removed')) {
              treeData.splice(index, 1);
            } else if (treeData[index].children.length) {
              iterateToRemove(treeData[index].children);
            }
          }
        });
      };

      iterateToRemove(draft);
    }
  });
};

const move = <T extends GenericNodeRemove<T>>(
  tree: T[],
  sourcePath: ITargetPath,
  destinationPath: ITargetPath
): T[] => {
  return produce(tree, (draft) => {
    let sourceElement: Draft<T> | null = null;
    const iterateSourcePath = (
      treeData: Draft<T>[],
      level: number,
      path: ITargetPath
    ) => {
      const index = path[level];

      if (path.length === level + 1) {
        sourceElement = { ...treeData[index] };
        treeData[index]['removed'] = true;
      } else if (treeData[index].children.length && path.length !== level + 1) {
        iterateSourcePath(treeData[index].children, level + 1, path);
      }
    };

    iterateSourcePath(draft, 0, sourcePath);

    const iterateDestinationPath = (
      treeData: Draft<T>[],
      level: number,
      path: ITargetPath
    ) => {
      const index = path[level];

      if (!treeData[index]) {
        return;
      }

      if (path.length === level + 1) {
        if (sourceElement) {
          treeData[index].children.push(sourceElement);
        }
      } else if (treeData[index].children.length && path.length !== level + 1) {
        iterateDestinationPath(treeData[index].children, level + 1, path);
      }
    };

    if (sourceElement) {
      if (destinationPath.length) {
        iterateDestinationPath(draft, 0, destinationPath);
      } else {
        draft.push(sourceElement);
      }

      const iterateToRemove = (treeData: Draft<T>[]) => {
        treeData.forEach((d, index) => {
          if (d.title !== '.primitives') {
            if (d.hasOwnProperty('removed')) {
              treeData.splice(index, 1);
            } else if (treeData[index].children.length) {
              iterateToRemove(treeData[index].children);
            }
          }
        });
      };

      iterateToRemove(draft);
    }
  });
};

const generateDatasetForAsset = (
  key: CherryKey,
  type: TreeNodeType,
  assetKey: CherryKey = '-1'
): ObjectDataSet | null => {
  const title = assetsHelpers.titleNameFrom(undefined, type);

  return objectsDataSet(key, type, assetKey, title);
};

const generateDatasetForPrimitive = (
  key: CherryKey,
  type: TreeNodeType,
  assets: Asset[],
  primitiveType?: PrimitiveType
): ObjectDataSet | null => {
  const primitives = assetsHelpers.getPrimitives(assets);
  const primitiveTitle = `${primitiveType?.toLowerCase()}.c3b`;
  const id = assetsHelpers.getPrimitiveKey(primitives, primitiveTitle);
  const title = assetsHelpers.titleNameFrom(primitiveType, type);

  return objectsDataSet(key, type, id, title, primitiveType);
};

export default {
  addBefore,
  addTo,
  canMove,
  changeOrder,
  checkIfCanAddTo,
  copy,
  generateDatasetForAsset,
  generateDatasetForPrimitive,
  getAllNodesBy,
  getNodeBy,
  getNodeByKey,
  getNodeByProperty,
  getPathBy,
  getParentNode,
  move,
  paste,
  removeNode,
  removeNodeByKey,
  iterateTree,
  updateNodeBy,
  updateNodesVisibility,
  updateNodeByKey,
  isAnyChildHighlighted,
  addChildrenToNode,
};
